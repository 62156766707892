<template>
  <div class="mask" v-if="isShowLoading">
    <div class="load-wrap">
      <div class="loading">
      </div>
    </div>
    
  </div>
  
</template>

<script>
export default {
  data () {
    return {
      isShowLoading: false,
    }
  }
}
</script>

<style scoped>
.mask {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-wrap {
  background: rgba(0,0,0,0.4);
  padding: 30px 80px;
  border-radius: 20px;
}
.loading {
    width: 60px;
    height: 60px;
    border: 8px solid rgb(209, 208, 208);
    border-radius: 50%;
    border-left-color: #006BFF;
    animation: loading 1s infinite linear;
}

@keyframes loading {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
</style>