<!--
 * @Descripttion:
 * @version:
 * @Author: tina.cai
 * @Date: 2021-04-16 11:51:52
 * @LastEditors: tina.cai
 * @LastEditTime: 2021-04-16 11:59:07
-->
<template>
	<div>
		<keep-alive>
			<router-view :key="$route.fullPath" v-if="$route.meta.keepAlive"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"></router-view>
	</div>

</template>

<script>
import {decrypt, changeLanguage, GetRequestParam} from './assets/js/common';
export default {
  mounted () {
    var map = {}
    if (GetRequestParam().orderInfo != null) {
      map = decrypt(GetRequestParam().orderInfo);
    }
    changeLanguage(map)
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign({},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        ))
      sessionStorage.removeItem("store")
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    this.$nextTick(() => {
      let realHeight =window.innerWidth > window.innerHeight ? window.innerWidth :
      window.innerHeight
      this.setMetaHeight(realHeight)
    })
  },

  methods:{
    // 设置meta高度 解决安卓浏览器软键盘弹起，占用窗口的面积问题
    setMetaHeight(height) {
      document.head.querySelector("meta[name='viewport']").setAttribute('id', 'viewportMeta')
      let metaEl = document.querySelector('#viewportMeta')
      let content = 'height=' + height + ',width=device-width,initial-scale=1.0,user-scalable=no'
      metaEl.setAttribute('name', 'viewport')
      metaEl.setAttribute('content', content)
      this.$forceUpdate()
    }
  },
}
</script>

<style lang="scss">
	@import './assets/css/reset.scss';
</style>
