import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n.js"
import store from './store/index'
import '@/assets/js/http'
import Loading from '@/assets/js/loading'
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {PaymentBrand} from "@/assets/js/common";
//导入css

Vue.use(ElementUI);
Vue.use(Mint);
Vue.use(Loading)




/**
 * 忽略大小写判断字符串str是否包含subStr
 * @param subStr 子字符串
 * @param str 父字符串
 * @returns boolean
 */
function coverString(subStr, str) {
	var reg = eval("/" + subStr + "/ig");
	return reg.test(str);
}


function getPaymentBrand() {
	const localHost = location.host;
	if (coverString("allo", localHost)) {
		return PaymentBrand.ALLOPAY.name;
	} else if (coverString("tena", localHost)) {
		return PaymentBrand.TENAPAY.name;
	} else {
		return PaymentBrand.TIKIPAY.name;
	}
}

localStorage.setItem('paymentBrand', getPaymentBrand())



router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})


if (localStorage.lang !== undefined) {
	i18n.locale = localStorage.getItem('lang')
} else {
	localStorage.setItem("lang", "en-US")
	i18n.locale = localStorage.getItem('lang')
}
new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')


const path = window.location.pathname;
const newAppPage = ["/demo","/id/demo","/ph/demo","/th/demo","/my/demo","/vn/demo","/la/demo","/mx/spei"]

if (newAppPage.includes(path)) {
	import("./assets/css/demo.scss");
} else {
	import("./assets/css/checkout.scss");
}