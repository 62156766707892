import VueI18n from "vue-i18n"
import Vue from "vue"

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || "en-US", //语言标识
  messages: {
    "en-US": require("./locales/en-US.json"), // 英文
    "th-THA": require("./locales/th-THA.json"),
    "my-MYS": require("./locales/my-MYS.json"),
    "id-IDN": require("./locales/id-IDN.json"), // 印尼语
    "ms-MY": require("./locales/ms-MY.json"), // 马来语
    "pt-BR": require("./locales/pt-BR.json"), // 巴西-葡萄牙语
    "es-MX": require("./locales/es-MX.json"), // 墨西哥-西班牙语
    "zh-CN": require("./locales/zh-CN.json"), // 中文
  },
  fallbackLocale: "en-US",
})
